import React from "react"
import { graphql } from "gatsby"
import SEO from "components/seo"
import Layout from "components/layout"
import Markdown from "components/markdown"

const MrsVancouverIslandPage = ({ data }) => {
  const {
    body: {
      childMarkdownRemark: { html },
    },
    title,
  } = data.contentfulPage

  return (
    <Layout>
      <SEO title="Mrs. Vancouver Island" />
      <h1>{title}</h1>
      <Markdown html={html} />
    </Layout>
  )
}

export const mrsVancoouverIslandPageQuery = graphql`
  query mrsVancoouverIslandPageQuery {
    contentfulPage(contentful_id: { eq: "5308A5NyXYyXYzNz0gd0in" }) {
      body {
        childMarkdownRemark {
          html
        }
      }
      title
    }
  }
`

export default MrsVancouverIslandPage
